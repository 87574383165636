import React, { useEffect } from 'react'
import PageWrapper from '../components/PageWrapper'

import styled from 'styled-components'
import { Link as RouterLink } from 'react-router-dom'

import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'

import usePageHead from '../hooks/usePageHead'

import DownloadAppCoverImg from '../images/map.png'
import { ReactComponent as AppIconSvg } from '../images/app-icon.svg'
// import { ReactComponent as AppStoreBadgeSvg } from '../images/app-store-badge.svg'
import GooglePlayBadgeImg from '../images/google-play-badge.png'
import BusinessPowerUpImg from '../images/business-power-up.jpg'

const DownloadAppSection = styled.section`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: 75vh;
  width: 100%;
`

const DownloadAppCover = styled.div`
  position: absolute;
  background: url(${DownloadAppCoverImg});
  background-size: cover;
  background-position: center;
  filter: blur(4px) grayscale(20%);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.8;
`

const DownloadButton = styled(Link)`
  text-decoration: none;
  padding: 0;
  text-align: center;
`

const FewlsyBusinessSection = styled.section`
  background-color: #ffffff;
  position: relative;
  width: 100%;
  padding-top: 4rem;
  padding-bottom: 4rem;
  min-height: 25vh;
`

const FewlsyBusinessDetailsDescription = styled.p`
  padding: 0;
  color: #777777;
  font-size: 1.15rem;
  line-height: 1.75rem;
`

const SignUpButton = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  outline: 0px;
  border: 0px;
  margin: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  min-width: 64px;
  padding: 8px 22px;
  border-radius: 4px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: #ffffff;
  background-color: #2979ff;
  box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;
  :hover {
    text-decoration: none;
    background-color: #1c54b2;
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 4px -1px, rgb(0 0 0 / 14%) 0px 4px 5px 0px, rgb(0 0 0 / 12%) 0px 1px 10px 0px;
  }
`

const NoBr = styled.span`
  white-space: nowrap;
`

export default function HomePage() {
  const { updatePageHead } = usePageHead()
  const theme = useTheme()
  const mdUp = useMediaQuery(theme.breakpoints.up('md'))

  useEffect(() => {
    updatePageHead({
      title: 'Find the best fuel prices in town with Fewlsy',
      description: 'Fewlsy helps you search for fuel stations, better prices, and a lot more!'
    })

    window.scroll({
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
     });
  }, [updatePageHead])

  return <PageWrapper>
    <DownloadAppSection>
      <DownloadAppCover />
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" width="100%" position="relative" py={mdUp ? 10 : 2} px={2}>
        <>
          {mdUp &&
            <Box mb={4}>
              <AppIconSvg width={256} height={256} />
            </Box>
          }
        </>
        <Box mb={mdUp ? 4 : 2} textAlign="center">
          <Typography component="h1" variant={mdUp ? 'h2' : 'h4'}>Find the best fuel prices <NoBr>in town</NoBr></Typography>
        </Box>
        <Box mb={1} textAlign="center">
          <Typography component="p" variant={mdUp ? 'h5' : 'body1'}><b>Fewlsy</b> helps you search for fuel stations, better prices, discounts, and a lot more!</Typography>
        </Box>
        <Box mb={mdUp ? 6 : 4} textAlign="center">
          <Typography component="p" variant={mdUp ? 'h6' : 'body2'}>Download the Fewlsy app for Android today</Typography>
        </Box>
        <Box display="flex" flexDirection={mdUp ? 'row' : 'column' } justifyContent="center" alignItems="center" width="100%" maxWidth={mdUp ? 450 : 'none'} mx="auto">
          <DownloadButton href="/#todo" target="_blank" rel="noreferrer">
            <img src={GooglePlayBadgeImg} width="215" height="64" alt="Download Fewlsy on Google Play" />
          </DownloadButton>
          {/* <DownloadButton href="/#todo" target="_blank" rel="noreferrer">
            <AppStoreBadgeSvg width="191" height="64" />
          </DownloadButton> */}
        </Box>
      </Box>
    </DownloadAppSection>
    <FewlsyBusinessSection>
      <Box display="flex" flexDirection={mdUp ? 'row' : 'column' } justifyContent={mdUp ? 'space-between' : 'center' } alignItems="center" maxWidth={mdUp ? 'md' : 'none'} mx="auto">
        <Box maxWidth="xs" px={2} mb={mdUp ? 0 : 4}>
          <Box mb={4}>
            <Typography component="h2" variant="h4">Sign up for<br/><Typography color="primary" fontWeight="bold" component="span" variant="h4">Fewlsy Business</Typography></Typography>
            <FewlsyBusinessDetailsDescription>Expand your reach, increase sales, and fuel up your business with Fewlsy Business.</FewlsyBusinessDetailsDescription>
          </Box>
          <Box mb={2} textAlign={mdUp ? 'left' : 'center'}>
            <SignUpButton href={`${process.env.REACT_APP_FEWLSY_BUSINESS_APP_DOMAIN}/register`}>Sign Up</SignUpButton>
          </Box>
          <Box textAlign={mdUp ? 'left' : 'center'}><span>or</span> <Link color="secondary" component={RouterLink} to="/business">Learn more</Link></Box>
        </Box>
        <Box textAlign="center">
          <img src={BusinessPowerUpImg} width={mdUp ? 600 : 300} height={mdUp ? 400 : 200} alt="Power-up your fuel station and apply for Fewlsy Business" />
        </Box>
      </Box>
    </FewlsyBusinessSection>
  </PageWrapper>
}
