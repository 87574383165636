import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom';
import {
  createTheme,
  ThemeProvider as MuiThemeProvider
} from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from 'styled-components';

// import ComingSoon from './pages/ComingSoon'
import HomePage from './pages/Home'
import BusinessPage from './pages/Business'

import './styles/index.scss'

const customTheme = createTheme({
  palette: {
    primary: {
      dark: '#0c7739',
      light: '#41bb74',
      main: '#12ab52',
      contrastText: '#FFFFFF'
    },
    secondary: {
      main: '#2979ff',
      dark: '#1c54b2',
      light: '#5393ff',
      contrastText: '#FFFFFF'
    }
  }
});

function AppRouter () {
  return <BrowserRouter>
    <Routes>
      <Route path="/">
        <Route path="business" element={<BusinessPage />} />
        <Route path="" element={<HomePage />} />
        {/* <Route path="home" element={<HomePage />} /> */}
        {/* <Route path="" element={<ComingSoon />} /> */}
      </Route>
    </Routes>
  </BrowserRouter>
}

function App() {
  return <>
    <CssBaseline />
    <MuiThemeProvider theme={customTheme}>
      <ThemeProvider theme={customTheme}>
        <AppRouter />
      </ThemeProvider>
    </MuiThemeProvider>
  </>
}

export default App;
