import React, { useEffect } from 'react'
import PageWrapper from '../components/PageWrapper'

import styled from 'styled-components'

import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import Divider from '@mui/material/Divider'

import usePageHead from '../hooks/usePageHead'

import { ReactComponent as AppIconSvg } from '../images/app-icon.svg'
import BusinessPowerUpImg from '../images/business-power-up.jpg'
import BusinessRating from '../images/bp-rating.png'
import BusinessInfo from '../images/bp-info.png'
import BusinessExpand from '../images/bp-expand.png'
import BusinessAnalytics from '../images/bp-data-analytics.png'
import BusinessGasStation from '../images/gas-station.jpeg'

type MaximizeSectionItemProps = {
  type: 'leadGeneration' | 'information' | 'customerExperience' | 'insights'
}

const SignUpButton = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  outline: 0px;
  border: 0px;
  margin: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 1.2375rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  min-width: 64px;
  padding: 12px 40px;
  border-radius: 4px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: #ffffff;
  background-color: #2979ff;
  box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;
  :hover {
    text-decoration: none;
    background-color: #1c54b2;
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 4px -1px, rgb(0 0 0 / 14%) 0px 4px 5px 0px, rgb(0 0 0 / 12%) 0px 1px 10px 0px;
  }
`

type MediaQueries = {
  xlUp?: boolean
  lgUp?: boolean
  mdUp?: boolean
  smUp?: boolean
  xsUp?: boolean
}

type MediaValue = {
  xlUp?: any
  lgUp?: any
  mdUp?: any
  smUp?: any
  xsUp?: any
  default: any
}

type MediaQueryKeys = keyof MediaQueries

export default function BusinessPage() {
  const { updatePageHead } = usePageHead()
  const theme = useTheme()
  const mdUp = useMediaQuery(theme.breakpoints.up('md'))
  const lgUp = useMediaQuery(theme.breakpoints.up('lg'))
  const xlUp = useMediaQuery(theme.breakpoints.up('xl'))

  function mqValue(values: MediaValue) {
    let result = values.default
    
    const mediaQueries: MediaQueries = {
      mdUp,
      lgUp,
      xlUp
    }

    const mediaQueryKeys: MediaQueryKeys[] = ['xsUp', 'smUp', 'mdUp', 'lgUp', 'xlUp']
    mediaQueryKeys.forEach((v) => {
      if (mediaQueries[v] && values[v] !== undefined) {
        result = values[v]
      }
    })

    return result
  }

  function MaximizeSectionItem (props: MaximizeSectionItemProps) {
    const { type } = props
    const types = {
      leadGeneration: {
        img: BusinessExpand,
        imgAlt: 'Fewlsy helps expand your reach',
        title: 'Lead Generation',
        description: 'The Fewlsy platform connects drivers with nearby fuel stations, including yours. Expand your reach and start attracting more customers to your fuel station.'
      },
      information: {
        img: BusinessInfo,
        imgAlt: 'Fewlsy helps you make your info more accessible to your customers',
        title: 'Information',
        description: 'Fewlsy Business makes it easier to share with your customers your fuel prices, directions, facilities, operating hours and more. Focus on providing great service, while we do the rest.',
      },
      customerExperience: {
        img: BusinessRating,
        imgAlt: 'Fewlsy helps you improve your customer experience',
        title: 'Customer Experience',
        description: 'Fewlsy Business provides you with tools to enhance customer experiences, improve satisfaction, and drive loyalty.',
      },
      insights: {
        img: BusinessAnalytics,
        imgAlt: 'Fewlsy helps you make data-driven decisions',
        title: 'Insights',
        description: 'Unlock the power of data! Fewlsy Business provides valuable insights into how your business is performing. Make informed decisions and take actionable steps to improve your operations.',
      }
    }

    return <Box
      sx={{ backgroundColor: 'rgb(0 0 0 / 65%)' }}
      mx={mqValue({ lgUp: 0, default: 2 })}
      mb={2}
      py={mqValue({ lgUp: 2, mdUp: 8, default: 4 })}
      px={mqValue({ lgUp: 2, mdUp: 4, default: 2 })}
      maxWidth={mqValue({ lgUp: 350, default: 'none' })}
      minHeight={mqValue({ lgUp: 350, default: 'none' })}
    >
      <Box display="flex" flexDirection="column" justifyContent={mdUp ? 'center' : 'flex-start'} alignItems="flex-start">
        <Box mb={1}>
          <img src={types[type].img} width={48} height={48} alt={types[type].imgAlt} style={{ opacity: 0.5 }} />
        </Box>
        <Typography component="h2" color="white" fontSize={mqValue({ mdUp: '1.8rem', default: '1.5rem' })}>{types[type].title}</Typography>
        <Typography fontSize={mqValue({ mdUp: '1.25rem', default: '1rem' })} fontWeight="normal" color="#cee8d9">{types[type].description}</Typography>
      </Box>
    </Box>
  }

  useEffect(() => {
    updatePageHead({
      title: 'Power-up your fuel station business with Fewlsy Business',
      description: 'Fewlsy Business helps expand your reach, boost sales, and more'
    })

    window.scroll({
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
     });
  }, [updatePageHead])

  return <PageWrapper>
    <Container component="section">
      <Box minHeight="50vh" display="flex" flexDirection={mqValue({ lgUp: 'row', default: 'column' })} justifyContent="center" alignItems="center" py={mqValue({ lgUp: 10, default: 4 })}>
        <Box textAlign="center">
          <Box mb={mqValue({ lgUp: 4, default: 1 })}>
            <img src={BusinessPowerUpImg} width={mqValue({ lgUp: 600, default: 300 })} height={mqValue({ lgUp: 400, default: 200 })} alt="Fuel up your fuel station and apply for Fewlsy Business" />
          </Box>
        </Box>
        <Box textAlign={mqValue({ lgUp: 'left', default: 'center' })}>
          <Box mb={mdUp ? 4 : 1}>
            <Typography component="h1" variant={mqValue({ mdUp: 'h3', default: 'h4' })} fontWeight="bold" color="primary">Fuel up your business</Typography>
          </Box>
          <Box mb={4}>
            <Typography component="p" variant={mqValue({ mdUp: 'h5', default: 'body1' })}>Fewlsy Business helps maximize your business performance and revenue by increasing lead generations, improving customer experience, and more.</Typography>
          </Box>
          <Box>
            <SignUpButton href={`${process.env.REACT_APP_FEWLSY_BUSINESS_APP_DOMAIN}/register`}>Sign Up</SignUpButton>
          </Box>
        </Box>
      </Box>
    </Container>
    <Container component="section" style={{ maxWidth: 'none', width: '100%', padding: 0 }}>
      <Box
        sx={{ backgroundColor: '#12ab52', background: 'linear-gradient(to bottom right, #12ab52, #fff312)' }}
        width="100%"
        display="flex"
        flexDirection={mqValue({ lgUp: 'row', default: 'column' })}
        justifyContent={mqValue({ lgUp: 'center', default: 'flex-start' })}
        alignItems={mqValue({ lgUp: 'flex-start', default: 'flex-start' })}
        py={mqValue({ lgUp: 10, default: 2 })}
        px={mqValue({ lgUp: 10, default: 0 })}
      >
        <Box mr={mqValue({ lgUp: 4, default: 0 })} p={mqValue({ lgUp: 0, default: 4 })}>
          <Box maxWidth={mqValue({ lgUp: 350, mdUp: 'none', default: 350 })}>
            <Typography color="white" variant="body1" fontSize={mqValue({ mdUp: 22, default: 18 })}>We help you</Typography>
            <Typography color="white" fontWeight="bold" component="h2" fontSize={mqValue({ lgUp: 56, mdUp: 48, default: 32 })}>Maximize Performance and Revenue</Typography>
          </Box>
        </Box>
        <Box flexDirection="column" mr={mqValue({ lgUp: 2, default: 0 })}>
          <MaximizeSectionItem type="leadGeneration" />
          <MaximizeSectionItem type="customerExperience" />
        </Box>
        <Box flexDirection="column">
          <MaximizeSectionItem type="information" />
          <MaximizeSectionItem type="insights" />
        </Box>
      </Box>
    </Container>
    <Container component="section" style={{ maxWidth: 'none', width: '100%', padding: 0, position: 'relative' }} >
      <Box display="flex" flexDirection={mqValue({ lgUp: 'row', default: 'column'})}>
        <Box display="flex" alignItems="center" justifyContent="center" mt={mqValue({ lgUp: 0, mdUp: 4, default: 0 })}>
          <img
            src={BusinessGasStation}
            alt="Fewlsy can help fuel stations of all sizes"
            width={mqValue({ lgUp: '100%', mdUp: '80%', default: '100%' })}
            height="auto"
            style={{ display: 'block', minWidth: mqValue({ lgUp: 700, default: 'none' })  }}
          />
        </Box>
        <Box maxWidth="lg" marginX="auto" alignSelf="stretch" display="flex" alignItems="center" justifyContent="center">
          <Box p={4}>
            <Box mb={2}>
              <Typography color="primary" fontWeight="bold" component="h2" fontSize={mqValue({ lgUp: 56, mdUp: 48, default: 32 })}>For all businesses</Typography>
            </Box>
            <Typography variant="body1" fontSize={mqValue({ lgUp: 24, mdUp: 20, default: 18 })}>
              Whether you're operating dozens of fuel stations or a single independent fuel station — Fewlsy can help! Our innovative platform is designed to upgrade businesses of all sizes with powerful tools and features that drive growth and success. Choose Fewlsy today and experience the future of fuel stations!
            </Typography>
          </Box>
        </Box>
      </Box>
      <Divider />
    </Container>
    <Container>
      <Box textAlign="center" py={mqValue({ lgUp: 20, default: 10 })} maxWidth={800} mx="auto">
        <Box mb={4}>
          <Typography component="p" variant={mqValue({ lgUp: 'h4', default: 'h5' })}>Sign up for a <b>FREE</b> Fewlsy Business account today</Typography>
        </Box>
        <Box>
          <SignUpButton href={`${process.env.REACT_APP_FEWLSY_BUSINESS_APP_DOMAIN}/register`}>Sign Up</SignUpButton>
        </Box>
      </Box>
      <Box
        sx={{
          textAlign: 'center',
          maxHeight: mqValue({ mdUp: '186px', default: '100px' }),
        }}
      >
        <AppIconSvg width={mqValue({ mdUp: 512, default: 256 })} height={mqValue({ mdUp: 512, default: 256 })} />
      </Box>
    </Container>
  </PageWrapper>
}
