import React, { useState } from 'react'
import styled from 'styled-components'
import { Link as RouterLink } from 'react-router-dom'

import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import Divider from '@mui/material/Divider'

import MenuIcon from '@mui/icons-material/Menu'

import { ReactComponent as AppIconSvg } from '../images/app-icon.svg'

const HeaderWrapper = styled.header`
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
`
const HeaderBrand = styled.div``
const HeaderBrandButton = styled(RouterLink)`
  text-decoration: none;
  padding: 0;
`
const HeaderBrandButtonContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`
const HeaderBrandIcon = styled(AppIconSvg)`
  margin-right: 0.5rem;
`
const HeaderBrandName = styled.span`
  font-size: 1.15rem;
  font-weight: 700;
  color: #12ab52;
  &.md {
    font-size: 1.75rem;
  }
`
const HeaderNav = styled.nav`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-start;
`

const HeaderNavLink = styled(RouterLink)({
  margin: 0,
  border: 0,
  padding: '0.5rem 0.75rem',
  marginLeft: '1rem',
  textDecoration: 'none',
  display: 'inline-block',
  color: '#333333',
  fontSize: '1rem',
  borderTop: 'solid 3px transparent',
  borderBottom: 'solid 3px transparent',
  transition: 'all 150ms linear',
  '&:hover': {
    color: '#2979ff',
    borderBottomColor: '#2979ff',
  },
})

export default function Header() {
  const theme = useTheme()
  const mdUp = useMediaQuery(theme.breakpoints.up('md'))

  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false)

  if (mdUp) {
    return <HeaderWrapper>
      <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-start" px={4}>
        <HeaderBrand>
          <HeaderBrandButton to="/">
            <HeaderBrandButtonContent>
              <HeaderBrandIcon width="32" height="32" />
              <HeaderBrandName className="md">Fewlsy</HeaderBrandName>
            </HeaderBrandButtonContent>
          </HeaderBrandButton>
        </HeaderBrand>
        <HeaderNav>
          {/* <HeaderNavLink to="/#todo">For Vehicle Owners</HeaderNavLink> */}
          <HeaderNavLink to="/business">For Fuel Station Owners</HeaderNavLink>
        </HeaderNav>
      </Box>
    </HeaderWrapper>
  }

  return <HeaderWrapper>
    <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" px={2}>
      <IconButton onClick={() => setIsDrawerOpen(true)}>
        <MenuIcon />
      </IconButton>
      <HeaderBrand>
        <HeaderBrandButton to="/">
          <HeaderBrandButtonContent>
            <HeaderBrandIcon width="32" height="32" />
          </HeaderBrandButtonContent>
        </HeaderBrandButton>
      </HeaderBrand>
      <Box width={40} height={40} />
    </Box>
    <Box>
      <Drawer
        anchor="left"
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
      >
        <Box minWidth="70vw">
          <Box px={2} py={1}>
            <HeaderBrand>
              <HeaderBrandButton to="/">
                <HeaderBrandButtonContent>
                  <HeaderBrandIcon width="28" height="28" />
                  <HeaderBrandName>Fewlsy</HeaderBrandName>
                </HeaderBrandButtonContent>
              </HeaderBrandButton>
            </HeaderBrand>
          </Box>
          <Divider />
          <nav>
            <List>
              {/* <ListItem disablePadding>
                <ListItemButton component={RouterLink} to="/#todo">
                  <ListItemText primary="For Vehicle Owners" />
                </ListItemButton>
              </ListItem> */}
              <ListItem disablePadding>
                <ListItemButton component={RouterLink} to="/business">
                  <ListItemText primary="For Gas Stations Owners" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton component={RouterLink} to="/#todo">
                  <ListItemText primary="Terms and Conditions" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton component={RouterLink} to="/#todo">
                  <ListItemText primary="Privacy Policy" />
                </ListItemButton>
              </ListItem>
            </List>
          </nav>
        </Box>
      </Drawer>
    </Box>
  </HeaderWrapper>
}
